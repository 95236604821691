import React from "react";
import { Votes } from "../../../api/Votes";
import Col from "react-bootstrap/lib/Col";
import Button from "react-bootstrap/lib/Button";
import Glyphicon from "react-bootstrap/lib/Glyphicon";

const Vote = (props) => {
  const createVote = () => {
    Votes.create(
      props.communityId,
      props.id,
      props.location.latitude,
      props.location.longitude
    ).then((response) => {
      props.fetchProposals();
      if (response.message) {
        props.displayAlert(response.message, "success");
      } else {
        props.displayAlert(response.error, "danger");
      }
    });
  };

  return (
    <>
    {props.vtList.includes(props.communityId.toString()) ? (
       <a href="#" id={"proposal-button"} >
        <a onClick={createVote} >
          <span className="vote-count">{props.votes}</span>
          <i className="material-icons grey"> favorite_border </i>
        </a>
      </a>
    ) : (
       <a href={"/block_layout?id=" + props.id} data-remote={"true"} id={"proposal-button"} >
        <a onClick={createVote} >
          <span className="vote-count">{props.votes}</span>
          <i className="material-icons"> favorite_border </i>
        </a>
      </a>
    )}</>
  );
};
export default Vote;
