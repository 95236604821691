import React, { useContext, useState } from "react";
import Well from "react-bootstrap/lib/Well";
import Form from "react-bootstrap/lib/Form";
import FormGroup from "react-bootstrap/lib/FormGroup";
import FormControl from "react-bootstrap/lib/FormControl";
import Button from "react-bootstrap/lib/Button";
import { Comments } from "../../../api/Comments";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { CompanyContext } from "../../CompanyContext";

const CommentForm = (props) => {
  const [commentDescription, setCommentDescription] = useState({
    description: "",
  });

  const [satisfactionStatus, setSatisfactionStatus] = useState({
    status: false
  });

  const handleDescriptionInput = (event) => {
    setCommentDescription({ description: event.target.value });
    setSatisfactionStatus({ status: props.satisfaction });
  };

  function satisfactionFormat (satisfaction) {
    if (satisfaction === true) {
      return 0;
    } else {
      return 1;
    }
  }

  const handleCommentSubmit = (event) => {
    event.preventDefault();
    Comments.create(
      props.communityId,
      props.proposalId,
      commentDescription.description,
      satisfactionFormat(props.satisfaction)
    ).then((response) => {
      if (response.message) {
        props.displayAlert(response.message, "success");
      } else {
        props.displayAlert(response.error, "danger");
      }
      Comments.index(props.communityId, props.proposalId).then((response) => {
        console.log("creating comment", response);
        props.setProposalComments({ comments: response });
        setCommentDescription({ description: "" });
        props.fetchCommunityProposal();
        props.fetchProposals();
      });
    });
  };

  const companyContext = useContext(CompanyContext);

  console.log("Comment Form Satisfaction: ", props.satisfaction);
  console.log("Comment Form Satisfaction: ", props.satis);

  return (
    <Form onSubmit={handleCommentSubmit} className="page-proposal border-none" >
      <FormGroup controlId="formControlsTextarea">
        <FormControl
          maxLength={companyContext.commentCharLimit}
          componentClass="textarea"
          value={commentDescription.description}
          placeholder="ABC..."
          onChange={handleDescriptionInput}
        />
      </FormGroup>
      <Row>
        <Col xs={5}>
          <span className="remaining-chars margin-remaining">
            ABC...:{" "}
            {companyContext.commentCharLimit -
              commentDescription.description.length}
          </span>
        </Col>
        <Col xs={2}>
          <a href={"/view_comments/?id=" + props.proposalId + "&community_id=" + props.communityId + "&back_title=" + props.pageTitle + "&back_to=" + props.proposalCode + "&satis=" + props.satisfaction } className="btn btn-exp-comments btn-block material-icons" style={{ fontSize: "35px" }}>
            keyboard_arrow_down
          </a>
        </Col>
        <Col xs={5}>
          <Button
            className="pull-right comment-submit btn btn-block btn-comment-send material-icons"
            bsStyle="primary"
            bsSize="small"
            type="submit"
            id="comment-submit"
            value="check"
            style={{ padding: "0px", width: "150px", height: "35px" }}
          >
            check
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default CommentForm;
