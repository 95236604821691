import ReactOnRails from "react-on-rails";

import MembershipModal from "../bundles/MembershipModal/components/MembershipModal";
import ProposalList from "../bundles/ProposalList/components/ProposalList";
import ProposalListVariation from "../bundles/ProposalList/components/ProposalListVariation";
import Comment from "../bundles/ProposalList/components/Comment";
import CommentForm from "../bundles/ProposalList/components/CommentForm";
import Notifications from "../bundles/ProposalList/components/Notifications";

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  MembershipModal,
  ProposalList,
  ProposalListVariation,
  Comment,
  CommentForm,
  Notifications,
});
