import React, { useState } from "react";
import Col from "react-bootstrap/lib/Col";
import Button from "react-bootstrap/lib/Button";
import Glyphicon from "react-bootstrap/lib/Glyphicon";
import { Shares } from "../../../api/Shares";
import SharableCommunitiesModal from "./SharableCommunitiesModal";

const Share = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleOpen = () => setShow(true);

  const [sharableCommunities, setSharableCommunities] = useState({
    list: [],
  });

  const displaySharableCommunities = () => {
    handleOpen();
    Shares.index(props.communityId).then((response) => {
      setSharableCommunities({ list: response });
    });
  };

  return (
    <>
    { props.vtList.includes(props.communityId.toString()) ? (
        <a id={"proposal-button"}  href="#"
         className="share-proposal">
          <i className="material-icons"> share </i>
        </a>
    ) : (
      <a  onClick={displaySharableCommunities} id={"proposal-button"}  href="#"
          data-remote={"true"} className="share-proposal">
        <i className="material-icons"> share </i>
      </a>
    )}
    <SharableCommunitiesModal
        show={show}
        handleClose={handleClose}
        sharableCommunities={sharableCommunities}
        communityId={props.communityId}
        id={props.id}
        displayAlert={props.displayAlert} />
    </>
  );
};


export default Share;
