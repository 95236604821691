export const Votes = {
  create(communityId, proposalId, lat, long) {
    return fetch(`/communities/${communityId}/votes`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        proposal: proposalId,
        location: { latitude: lat, longitude: long },
      }),
    }).then((res) => res.json());
  },
};
