import React, { useState } from "react";
import Well from "react-bootstrap/lib/Well";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Button from "react-bootstrap/lib/Button";
import Glyphicon from "react-bootstrap/lib/Glyphicon";
import Modal from "react-bootstrap/lib/Modal";
import { Comments } from "../../../api/Comments";

const Comment = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleOpen = () => setShow(true);

  const reportComment = () => {
    Comments.update(props.communityId, props.proposalId, props.commentId).then(
      (response) => {
        handleClose();
        props.setCommentPanel({ open: true });
        Comments.index(props.communityId, props.proposalId).then((response) => {
          props.setProposalComments({ comments: response });
        });
        if (response.message) {
          props.displayAlert(response.message, "success");
          props.fetchProposals();
        } else {
          props.displayAlert(response.error, "danger");
        }
      }
    );
  };

  

  return (
    <Well bsSize="small" className="page-proposal comment-background">
      <Row>
        <div className="proposal-line comment-line">
          <div className="pull-left" >
            <span className="comment-username">{props.userName}</span>
          </div>
          <div className="pull-right" >
            <a className="pull-right" onClick={handleOpen}>
              <Glyphicon glyph="flag" style={{ padding: 0, marginRight: 5 }} className="comment-flag" />
            </a>
            
            {/* { props.childrenCount > 0 ? (

            <a href={"/comments/" + props.commentId } >
              <span style={{ padding: 0, marginRight: 10, fontSize: 18, position: "relative", top: 3, color: "orange" }} className="material-icons no-decoration"> chat_bubble_outline </span>
            </a> 
            ) : (

              <a href={"/comments/" + props.commentId } >
                <span style={{ padding: 0, marginRight: 10, fontSize: 18, position: "relative", top: 3, color: "grey" }} className="material-icons no-decoration"> chat_bubble_outline </span>
              </a> 

            ) } */}
            
        </div>
        </div>
      </Row>
      <Row>
        <div className="proposal-line comment-line" >
          <div className="pull-left">
            <span className="comment-text" dangerouslySetInnerHTML={{ __html: props.description }}></span>
          </div>
        </div>
      </Row>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          Deseja realmente denunciar esse comentário?
        </Modal.Header>
        <Modal.Body>
          <Button onClick={reportComment} bsStyle="primary">
            Denunciar
          </Button>
          <Button onClick={handleClose}>Cancelar</Button>
        </Modal.Body>
      </Modal>
    </Well>
  );
};

export default Comment;
