import React from "react";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Image from "react-bootstrap/lib/Image";
import Button from "react-bootstrap/lib/Button";
import Tooltip from "react-bootstrap/lib/Tooltip";
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";
import { ProposalSubscriptions } from "../../../api/ProposalSubscriptions";

const ProposalHeader = (props) => {
  const activateSubscriptions = () => {
    ProposalSubscriptions.create(props.communityId, props.id).then(
      (response) => {
        if (response.message) {
          props.displayAlert(response.message, "success");
        } else {
          props.displayAlert(response.error, "danger");
        }
        props.fetchProposals();
      }
    );
  };

  const deactivateSubscriptions = () => {
    ProposalSubscriptions.delete(props.communityId, props.id).then(
      (response) => {
        if (response.message) {
          props.displayAlert(response.message, "success");
        } else {
          props.displayAlert(response.error, "danger");
        }
        props.fetchProposals();
      }
    );
  };

  const tooltipOn = <Tooltip id="tooltip">Desativar notificaçōes</Tooltip>;

  const tooltipOff = <Tooltip id="tooltip">Ativar notificaçōes</Tooltip>;

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-GB", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    });
  };

  const headerContent = (proposalOrder) => {
    if (proposalOrder === "creation_date") {
      return (
        <span className="date-time">
          Data de criação: {formatDate(props.creationDate)}
        </span>
      );
    } else if (proposalOrder === "vote_ranking") {
      return <span>{props.position}º</span>;
    }
  };

  return (
    <div className={ (props.vtList.includes(props.communityId.toString()) ? ("") : (""))} >
      <div className={"proposal-line"+ (props.vtList.includes(props.communityId.toString()) ? ("") : (""))} >
      <div className="pull-left">
        <>
          {props.vtList.includes(props.communityId.toString()) ? ( <i id={"mi-exclamation"} className="material-icons exc-on-rank"> warning </i>  ) : (headerContent(props.proposalDisplayOrder))}
        </>
      </div> 
      <div className={"pull-right"} >
        <a href={ '/communities/' + props.communityId + '/proposals/' + props.id }> 
          <span className="proposal-code">[{props.code}]</span>
        </a>
        {props.hasSubscribed === false ? (
          <OverlayTrigger placement="top" overlay={tooltipOff}>
            <a 
              style={{ padding: 0, color: "rgb(37, 139, 33)" }}
              onClick={activateSubscriptions}
            >
              <i className="fa fa-bell"/>
            </a>
          </OverlayTrigger>
        ) : (
          <OverlayTrigger placement="top" overlay={tooltipOn}>
            <a
              style={{ padding: 0, color: "rgb(255, 105, 97)" }}
              onClick={deactivateSubscriptions}
            >
              <i className="fa fa-bell active" />
            </a>
          </OverlayTrigger>
        )}
        </div>
        <br></br>
        <br></br>
        <span className="description" dangerouslySetInnerHTML={{ __html: props.description }} />
      </div>
      <Row>
        <Image src={props.image} responsive className="image" />
      </Row>
    </div>
  );
};

export default ProposalHeader;
