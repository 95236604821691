import React, { useState } from "react";
import MenuItem from "react-bootstrap/lib/MenuItem";
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import { ProposalSubscriptions } from "../../../api/ProposalSubscriptions";
import userInterval from "../../../bundles/useInterval";

const Notification = (props) => {
  const [notificationShow, setNotificationShow] = useState({
    notifications: [],
  });

  const updateNotifications = () => {
    ProposalSubscriptions.show().then((response) => {
      setNotificationShow({ notifications: response.proposal_subscriptions });
    });
  };

  function dropdownButtonColor() {
    if (notificationShow.notifications.length > 0) {
      return { color: "rgb(37, 139, 33)" };
    }
  }

  userInterval(async () => {
    await updateNotifications();
  }, 3000);

  const displayDivider = (array, index) => {
    if (index !== array.length - 1) {
      return <MenuItem divider />;
    }
  };

  return (
    <DropdownButton
      bsStyle="link"
      className="fa fa-bell"
      noCaret
      id="dropdown-no-caret"
      title=""
      pullRight="true"
      style={dropdownButtonColor()}
    >
      {notificationShow.notifications.length > 0 ? (
        notificationShow.notifications.map((notification, index) => (
          <>
            <MenuItem
              id="notification"
              className="notification-menu-item"
              key={notification.id}
              href={`/communities/${notification.community_id}/proposals/${notification.proposal_id}?openComments=true`}
            >
              Novos comentários na proposta {notification.code} da comunidade{" "}
              {notification.community_id}
            </MenuItem>
            {displayDivider(notificationShow.notifications, index)}
          </>
        ))
      ) : (
        <p>Você não tem nenhuma notificação</p>
      )}
    </DropdownButton>
  );
};

export default Notification;
