export const CommunityProposals = {
  specific(communityId, proposalId) {
    return fetch(`/communities/${communityId}/specific_proposal?proposal_id=${proposalId}&community_id=${communityId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
  },
};
