import React from "react";
import Col from "react-bootstrap/lib/Col";
import Button from "react-bootstrap/lib/Button";
import Glyphicon from "react-bootstrap/lib/Glyphicon";

const VetoProposal = (props) => {
  return (
    <>
      {(props.canBeVetoed === false && props.vtList.includes(props.communityId.toString())) || (props.restitued === true && props.restituedOn.includes(props.communityId.toString())) ? (
        <a  id={"proposal-button"} href="#">
          <i id={"mi-exclamation"} className="material-icons grey"> warning </i>
        </a>
      ) : (
        <a
          href={`/vetos/new?veto[proposal_id]=${props.proposalId}&veto[community_id]=${props.communityId}`}
          id={"proposal-button"}
        > 
          <i id={"mi-exclamation"} className="material-icons"> warning </i>
        </a>
      )}
    </>
  );
};

export default VetoProposal;
