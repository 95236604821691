export const Shares = {
  index(communityId) {
    return fetch(`/communities/${communityId}/shares`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
  },

  create(communityId, proposalId, sharedCommunityId) {
    return fetch(`/communities/${communityId}/shares`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        proposal: proposalId,
        shared_community: sharedCommunityId,
      }),
    }).then((res) => res.json());
  },
};
