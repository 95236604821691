import React from "react";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import Panel from "react-bootstrap/lib/Panel";
import VetoProposal from "./VetoProposal";
import Well from "react-bootstrap/lib/Well";
import Share from "./Share";


const AdditionalPanel = (props) => {


  return (
    <Row>
      <Col xs={12} id="additional-panel-main-col">
        <Panel
          className="additional-panel"
          id="collapsible-panel-example-1"
          expanded={props.additionalPanel.open}
          style={{ border: "none" }}
        >
          <Panel.Collapse style={{ border: "none" }}>
            <Panel.Body style={{ border: "none" }}>
              <div className="comment-wrapper" style={{ border: "none" }}>
                <a data-target={"#proposal-modal"}  href="#"
                  data-remote={"true"} data-toggle={"modal"} id={"proposal-button"}>
                  <i id={"mi-circle"} className="material-icons"> add_circle </i>
                </a>
                <VetoProposal
                  canBeVetoed={props.canBeVetoed}
                  proposalId={props.proposalId}
                  communityId={props.communityId}
                  vtList={props.vtList}
                  restitued={props.restitued}
                  restituedOn={props.restituedOn}
                />
                <Share
                  communityId={props.communityId}
                  id={props.proposalId}
                  displayAlert={props.displayAlert}
                  vtList={props.vtList}
                />
              </div>
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      </Col>
    </Row>
  );
};

export default AdditionalPanel;
