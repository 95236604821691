export const Proposals = {
  index(communityId) {
    return fetch(`/communities/${communityId}/votes`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
  },
};
