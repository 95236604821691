import React from "react";
import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { Shares } from "../../../api/Shares";

const SharableCommunitiesModal = (props) => {
  const shareCommunity = (communityId, proposalId, sharedCommunityId) => {
    Shares.create(communityId, proposalId, sharedCommunityId).then(
      (response) => {
        if (response.message) {
          props.displayAlert(response.message, "success");
        } else {
          props.displayAlert(response.error, "danger");
        }
      }
    );
    props.handleClose();
  };

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      animation={false}
      backdrop="static"
    >
      <Modal.Header closeButton className="share-modal-header">
        Escolha a comunidade para compartilhar a proposta
      </Modal.Header>
      {props.sharableCommunities.list.map((community) => (
        <Modal.Body key={community.id}>
          <Row>
            <Col md={9} xs={6}>
              <span>{community.name}</span>
            </Col>
            <Col
              md={3}
              xs={6}
              onClick={() =>
                shareCommunity(props.communityId, props.id, community.id)
              }
            >
              <Button bsStyle="warning">Compartilhar</Button>
            </Col>
          </Row>
          <hr className="shares-separator" />
        </Modal.Body>
      ))}
    </Modal>
  );
};

export default SharableCommunitiesModal;
