import React from "react";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import Panel from "react-bootstrap/lib/Panel";
import Comment from "./Comment";
import Well from "react-bootstrap/lib/Well";
import CommentForm from "./CommentForm";

function sortComments (comments, satisfied, actionMode) {
  if (actionMode.includes("threaded")) {
    comments = filterComments(comments, satisfied);
  }
  if (comments.length > 0 ) {
    return comments.sort(function(a, b) {
      return new Date(b.created_at) - new Date(a.created_at);
    }).slice(0, 4).reverse();
  } else {
    return comments;
  }
}

function filterComments (comments, satisfied) {
  if (comments.length > 0 && satisfied === true) {
    return comments.filter(function(comment) {
      return comment.satisfaction === "satisfied";
    });
  } else { 
    if (comments.length > 0) {
      return comments.filter(function (comment) {
        return comment.satisfaction === "dissatisfied";
      });
    } else {
      return comments;
    }
  }
}

const CommentPanel = (props) => {
  return (
    <Row>
      <Col xs={12} id="comment-panel-main-col">
        <Panel
          className="comment-panel"
          id="collapsible-panel-example-1"
          expanded={props.commentPanel.open}
        >
          <Panel.Collapse>
            <Panel.Body>
              {props.proposalComments.comments.length > 0 ? (
                sortComments(props.proposalComments.comments, props.satisfaction, props.proposalActionMode).map((comment) => (
                  <div className="comment-wrapper">
                    <Comment
                      key={comment.id}
                      communityId={comment.community_id}
                      commentId={comment.id}
                      userName={comment.user.name}
                      description={comment.description}
                      proposalId={comment.proposal_id}
                      setCommentPanel={props.setCommentPanel}
                      setProposalComments={props.setProposalComments}
                      displayAlert={props.displayAlert}
                      fetchProposals={props.fetchProposals}
                      childrenCount={comment.comments_count}
                      childreCount={comment.comments_count}
                      formattedComment={comment.formatted_comment}
                      satis={props.satis}
                    />
                  </div>
                ))
              ) : (
                <Well bsSize="small" className="page-proposal comment-background" >Ainda nao há comentários</Well>
              )}
              <CommentForm
                setCommentPanel={props.setCommentPanel}
                setProposalComments={props.setProposalComments}
                communityId={props.communityId}
                proposalId={props.proposalId}
                displayAlert={props.displayAlert}
                fetchProposals={props.fetchProposals}
                pageTitle={props.pageTitle}
                proposalCode={props.proposalCode}
                satisfaction={props.satisfaction}
                displayCommentForm={props.displayComments}
                satis={props.satis}
                fetchCommunityProposal={props.fetchCommunityProposal}
              />
              
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      </Col>
    </Row>
  );
};

export default CommentPanel;
