import React, { useState, useEffect } from "react";
import Proposal from "./Proposal";
import { Proposals } from "../../../api/Proposals";
import Well from "react-bootstrap/lib/Well";
import Alert from "react-bootstrap/lib/Alert";
import { UserContext } from "../../UserContext";
import { CompanyContext } from "../../CompanyContext";

const ProposalList = (props) => {

  const [satisfaction, setSatisfaction] = useState({
    status: false
  });

  const [proposalIndex, setProposalIndex] = useState({
    proposals: [],
  });

  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
  });

  const [alert, setAlert] = useState({
    message: "",
    style: "",
    show: false,
  });

  const displayAlert = (message, style) => {
    setAlert({ message: message, style: style, show: true });
    setTimeout(() => {
      setAlert({ ...alert, show: false });
    }, 4000);
  };

  function fetchProposals() {
    Proposals.index(props.communityId).then((response) => {
      if (props.proposalShowId) {
        const proposal = response.filter(
          (proposal) => proposal.id == props.proposalShowId
        );
        setProposalIndex({ proposals: proposal });
        // setSatisfaction({ status: initStatus });
      } else {
        setProposalIndex({ proposals: response });
        // setSatisfaction({ status: initStatus });
      }
    });
  }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    });
    fetchProposals();
  }, []);

  return (
    <CompanyContext.Provider
      value={{
        commentCharLimit: props.commentCharLimit,
        proposalCharLimit: props.proposalCharLimit,
      }}
    >
      <UserContext.Provider
        value={{
          currentUser: props.currentUser,
          isMember: props.isMember,
          isNotSuspended: props.isNotSuspended,
          reachedActivityLimit: props.reachedActivityLimit,
        }}
      >
        <div>
          {alert.show && (
            <Alert bsStyle={alert.style} className="alert">
              {alert.message}
            </Alert>
          )}
          {proposalIndex.proposals.length > 0 ? (
            proposalIndex.proposals.map((proposal, index) => (
              <Proposal
                id={proposal.id}
                code={proposal.code}
                description={proposal.description_html}
                image={proposal.proposal_image_path}
                key={proposal.id}
                index={index}
                votes={proposal.votes}
                communityId={props.communityId}
                canBeVetoed={proposal.can_be_vetoed}
                fetchProposals={fetchProposals}
                location={location}
                displayAlert={displayAlert}
                commentCount={proposal.comment_count}
                hasSubscribed={proposal.subscription}
                creationDate={proposal.created_at}
                proposalDisplayOrder={props.proposalDisplayOrder}
                vetoedOn={proposal.vetoed_on}
                restitued={proposal.restitued}
                restituedOn={proposal.restitued_list}
                pageTitle={props.pageTitle}
                proposalActionMode={props.proposalActionMode}
                satisfaction={satisfaction.status}
                satisfiedCount={proposal.satisfied_count}
                dissatisfiedCount={proposal.dissatisfied_count}
              />
            ))
          ) : (
            <Well bsSize="small">Ainda nao há propostas</Well>
          )}
        </div>
      </UserContext.Provider>
    </CompanyContext.Provider>
  );
};

export default ProposalList;
