export const ProposalSubscriptions = {
  create(communityId, proposalId) {
    return fetch(`/proposal_subscriptions`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        proposal_id: proposalId,
        community_id: communityId,
      }),
    }).then((res) => res.json());
  },
  delete(communityId, proposalId) {
    return fetch(`/proposal_subscriptions`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        proposal_id: proposalId,
        community_id: communityId,
      }),
    }).then((res) => res.json());
  },
  show() {
    return fetch(`/proposal_subscriptions`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
  },
  update(communityId, proposalId) {
    return fetch(`/proposal_subscriptions`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        proposal_id: proposalId,
        community_id: communityId,
      }),
    }).then((res) => res.json());
  },
};
