import React, { useContext, useState, useEffect } from "react";
import Well from "react-bootstrap/lib/Well";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Button from "react-bootstrap/lib/Button";
import { Comments } from "../../../api/Comments";
import { CommunityProposals } from "../../../api/CommunityProposals";
import VetoProposal from "./VetoProposal";
import CommentPanel from "./CommentPanel";
import ProposalHeader from "./ProposalHeader";
import Vote from "./Vote";
import VoteVariation from "./VoteVariation";
import Share from "./Share";
import AdditionalPanel from "./AdditionalPanel";
import { UserContext } from "../../UserContext";
import { ProposalSubscriptions } from "../../../api/ProposalSubscriptions";

const ProposalVariation = (props) => {


  const [commentPanel, setCommentPanel] = useState({
    open: false, satisfied: props.satisfaction,
  });

  const [additionalPanel, setAdditionalPanel] = useState({
    open: false,
  });

  const [proposalComments, setProposalComments] = useState({
    comments: [],
  });

  const [communityProposal, setCommunityProposal] = useState({
    satisfiedCount: 0, dissatisfiedCount: 0,
  });

  function fetchCommunityProposal() {
    CommunityProposals.specific(props.communityId, props.id).then((response) => {
      setCommunityProposal({ satisfiedCount: response.satisfied_count, dissatisfiedCount: response.dissatisfied_count });
    });
  }

  const getUrlParameters = () => {
    const parsedUrl = new URL(window.location.href);
    if (parsedUrl.searchParams.get("openComments")) {
      displayComments();
    }
  };

  const toggleComments = () => {
    if (commentPanel.open === true) {
      setCommentPanel({ open: false, satisfied: props.satisfaction });
    } else {
      setCommentPanel({ open: true, satisfied: props.satisfaction });
      displayComments();
    }
  };

  const toggleSatisfied = () => {
    if (commentPanel.satisfied === true && commentPanel.open === true) {
      setCommentPanel({ open: false, satisfied: true });
    } else {
      if (commentPanel.open === false) {
        setCommentPanel({ open: true, satisfied: true });
        displayComments(commentPanel.satisfied);
      }

      if (commentPanel.open === true && commentPanel.satisfied === false) {
        setCommentPanel({ open: true, satisfied: true });
        displayComments(commentPanel.satisfied);
      }
    }
  }

  const toggleDissatisfied = () => {
    if (commentPanel.satisfied === false && commentPanel.open === true) {
      console.log("toggle dissatisfied closed");
      setCommentPanel({ open: false, satisfied: false });
    } else {
      if (commentPanel.open === false) {
        console.log("toggle dissatisfied open");
        setCommentPanel({ open: true, satisfied: false });
        displayComments(commentPanel.satisfied);
      }

      if (commentPanel.open === true && commentPanel.satisfied === true) {
        console.log("toggle dissatisfied open");
        setCommentPanel({ open: true, satisfied: false });
        displayComments(commentPanel.satisfied);
      }
    }
  }

  const toggleAdditional = () => {
    if (additionalPanel.open === true) {
      setAdditionalPanel({ open: false });
    } else {
      setAdditionalPanel({ open: true });
    }
  };

  const displayComments = () => {
    Comments.index(props.communityId, props.id).then((response) => {
      setProposalComments({ comments: response });
      if (props.hasSubscribed === true) {
        ProposalSubscriptions.update(props.communityId, props.id).then();
      }
    });
  };

  useEffect(() => {
    getUrlParameters();
    fetchCommunityProposal();
  }, []);

  const userContext = useContext(UserContext);

  const vetoedCommunities = props.vetoedOn ? props.vetoedOn.split(",") : [];
  var vtList = [];
  const vetoedList = () => {
    if (vetoedCommunities.length > 0) {
      vetoedCommunities.map((communityId) => (
        vtList.push(communityId.replace("[", "").replace("]", ""))
      ));
    }
  };

  const restituedCommunities = props.restituedOn ? props.restituedOn.split(",") : [];
  var rtList = [];
  const restituedList = () => {
    if (restituedCommunities.length > 0) {
      restituedCommunities.map((communityId) => (
        rtList.push(communityId.replace("[", "").replace("]", ""))
      ));
    }
  };
  vetoedList();
  restituedList();

  return (  
    <section id={props.code} name={props.code} className={"proposal-well page-proposal last-row"+ (vtList.includes(props.communityId.toString()) ? ( " bg-grey") : (""))} key={props.id}>
      <ProposalHeader
        position={props.index + 1}
        votes={props.votes}
        code={props.code}
        description={props.description}
        image={props.image}
        communityId={props.communityId}
        id={props.id}
        displayAlert={props.displayAlert}
        hasSubscribed={props.hasSubscribed}
        fetchProposals={props.fetchProposals}
        creationDate={props.creationDate}
        proposalDisplayOrder={props.proposalDisplayOrder}
        vtList={vtList}
      />
      {userContext.reachedActivityLimit ? (
        <Well bsSize="small">
          "Por hoje acabou, saldo esgotado. Para apoiar e propor de novo, acesse
          depois da meia noite de hoje."
        </Well>
      ) : (
        userContext.currentUser &&
        userContext.isMember &&
        userContext.isNotSuspended && (

          <div className={"container-fluid"+(vtList.includes(props.communityId.toString()) ? ( "") : (""))}>
            <br></br>
            <Row className="text-center">
            { vtList.includes(props.communityId.toString()) ? (
              <>
              </>
            ) : props.proposalActionMode === "threaded_and_new" ? (
              <>
              { 
              props.satis_param === "true" ? (
                <a onClick={toggleSatisfied} id={"proposal-button"}  href="#"
                    data-remote={"true"} className="active-satisfaction">
                  
                  <i className="material-icons active-satisfaction satisfaction-button" > sentiment_satisfied </i>
                  <span className="comment-count">{communityProposal.satisfiedCount}</span>
                </a>
              ) : ( 
                <a onClick={toggleSatisfied} id={"proposal-button"}  href="#"
                    data-remote={"true"} className="comment-proposal">
                  
                  <i className="material-icons"> sentiment_satisfied </i>
                  <span className="comment-count">{communityProposal.satisfiedCount}</span>
                </a>
              )  
              }
              { 
              props.satis_param === "false" ? (
                <a onClick={toggleDissatisfied} id={"proposal-button"}  href="#"
                    data-remote={"true"} className="active-unsatisfaction satisfaction-button">
                  
                  <i className="material-icons"> sentiment_dissatisfied </i>
                  <span className="comment-count">{communityProposal.dissatisfiedCount}</span>
                </a>
              ) : ( 
                <a onClick={toggleDissatisfied} id={"proposal-button"}  href="#"
                    data-remote={"true"} className="comment-proposal">
                  
                  <i className="material-icons"> sentiment_dissatisfied </i>
                  <span className="comment-count">{communityProposal.dissatisfiedCount}</span>
                </a>
              )  
              }
              <a data-target={"#proposal-modal"}  href="#"
                  data-remote={"true"} data-toggle={"modal"} id={"proposal-button"}>
                <i id={"mi-circle"} className="material-icons"> add_circle </i>
              </a>
              </>
              ) : props.proposalActionMode === "threaded_and_vote" ? (
              <>
              <VoteVariation
                communityId={props.communityId}
                id={props.id}
                location={props.location}
                fetchProposals={props.fetchProposals}
                displayAlert={props.displayAlert}
                votes={props.votes}
                vtList={vtList}
              />
              { 
              props.satis_param === "true" ? (
                <a onClick={toggleSatisfied} id={"proposal-button"}  href="#"
                    data-remote={"true"} className="active-satisfaction">
                  
                  <i className="material-icons active-satisfaction satisfaction-button" > sentiment_satisfied </i>
                  <span className="comment-count">{communityProposal.satisfiedCount}</span>
                </a>
              ) : ( 
                <a onClick={toggleSatisfied} id={"proposal-button"}  href="#"
                    data-remote={"true"} className="comment-proposal">
                  
                  <i className="material-icons"> sentiment_satisfied </i>
                  <span className="comment-count">{communityProposal.satisfiedCount}</span>
                </a>
              )  
              }
              { 
              props.satis_param === "false" ? (
                <a onClick={toggleDissatisfied} id={"proposal-button"}  href="#"
                    data-remote={"true"} className="active-unsatisfaction satisfaction-button">
                  
                  <i className="material-icons"> sentiment_dissatisfied </i>
                  <span className="comment-count">{communityProposal.dissatisfiedCount}</span>
                </a>
              ) : ( 
                <a onClick={toggleDissatisfied} id={"proposal-button"}  href="#"
                    data-remote={"true"} className="comment-proposal">
                  
                  <i className="material-icons"> sentiment_dissatisfied </i>
                  <span className="comment-count">{communityProposal.dissatisfiedCount}</span>
                </a>
              )  
              }
              </>
              ) : ( 
              props.proposalActionMode === "threaded_comment" ? (
              <>
              <VoteVariation
                communityId={props.communityId}
                id={props.id}
                location={props.location}
                fetchProposals={props.fetchProposals}
                displayAlert={props.displayAlert}
                votes={props.votes}
                vtList={vtList}
              />
              { 
              props.satis_param === "true" ? (
                <a onClick={toggleSatisfied} id={"proposal-button"}  href="#"
                    data-remote={"true"} className="active-satisfaction">
                  
                  <i className="material-icons active-satisfaction satisfaction-button" > sentiment_satisfied </i>
                  <span className="comment-count">{communityProposal.satisfiedCount}</span>
                </a>
              ) : ( 
                <a onClick={toggleSatisfied} id={"proposal-button"}  href={props.otherLink}
                    className="comment-proposal">
                  
                  <i className="material-icons"> sentiment_satisfied </i>
                  <span className="comment-count">{communityProposal.satisfiedCount}</span>
                </a>
              )  
              }
              { 
              props.satis_param === "false" ? (
                <a onClick={toggleDissatisfied} id={"proposal-button"}  href="#"
                    data-remote={"true"} className="active-unsatisfaction satisfaction-button">
                  
                  <i className="material-icons"> sentiment_dissatisfied </i>
                  <span className="comment-count">{communityProposal.dissatisfiedCount}</span>
                </a>
              ) : ( 
                <a onClick={toggleDissatisfied} id={"proposal-button"}  href={props.otherLink}
                    className="comment-proposal">
                  
                  <i className="material-icons"> sentiment_dissatisfied </i>
                  <span className="comment-count">{communityProposal.dissatisfiedCount}</span>
                </a>
              )  
              }
              <a onClick={toggleAdditional} id={"proposal-button"}  href="#"
                  data-remote={"true"} className="additional-proposal">
                <i className="material-icons"> more_horiz </i>
              </a>
              <AdditionalPanel
                additionalPanel={additionalPanel}
                canBeVetoed={props.canBeVetoed}
                proposalId={props.id}
                vtList={vtList}
                restitued={props.restitued}
                restituedOn={rtList}
                proposalComments={proposalComments}
                setAdditionalPanel={setAdditionalPanel}
                setProposalComments={setProposalComments}
                communityId={props.communityId}
                displayAlert={props.displayAlert}
                fetchProposals={props.fetchProposals}
                pageTitle={props.pageTitle}
                proposalCode={props.code}
              />
              </>
              ) : ( 
              props.proposalActionMode === "comment_and_vote" ? (
                <>
                  <a onClick={toggleComments} id={"proposal-button"}  href="#"
                      data-remote={"true"} className="comment-proposal">
                    <span className="comment-count">{props.commentCount}</span>
                    <i className="material-icons"> chat_bubble_outline </i>
                  </a>
                  <Vote
                    communityId={props.communityId}
                    id={props.id}
                    location={props.location}
                    fetchProposals={props.fetchProposals}
                    displayAlert={props.displayAlert}
                    votes={props.votes}
                    vtList={vtList}
                  />
                </>
            ) : (
              props.proposalActionMode === "all" ? (
                <>
              <a onClick={toggleComments} id={"proposal-button"}  href="#"
                  data-remote={"true"} className="comment-proposal">
                <span className="comment-count">{props.commentCount}</span>
                <i className="material-icons"> chat_bubble_outline </i>
              </a>
              <a data-target={"#proposal-modal"}  href="#"
                  data-remote={"true"} data-toggle={"modal"} id={"proposal-button"}>
                <i id={"mi-circle"} className="material-icons"> add_circle </i>
              </a>
              <Vote
                communityId={props.communityId}
                id={props.id}
                location={props.location}
                fetchProposals={props.fetchProposals}
                displayAlert={props.displayAlert}
                votes={props.votes}
                vtList={vtList}
              />
              <VetoProposal
                canBeVetoed={props.canBeVetoed}
                proposalId={props.id}
                communityId={props.communityId}
                vtList={vtList}
                restitued={props.restitued}
                restituedOn={rtList}
              />
              <Share
                communityId={props.communityId}
                id={props.id}
                displayAlert={props.displayAlert}
                vtList={vtList}
              />
              </>
            ) : (
              <>
                <a onClick={toggleComments} id={"proposal-button"}  href="#"
                    data-remote={"true"} className="comment-proposal">
                  <span className="comment-count">{props.commentCount}</span>
                  <i className="material-icons"> chat_bubble_outline </i>
                </a>
                <a data-target={"#proposal-modal"}  href="#"
                    data-remote={"true"} data-toggle={"modal"} id={"proposal-button"}>
                  <i id={"mi-circle"} className="material-icons"> add_circle </i>
                </a>
              </>
            )
            )))}
            </Row>  
          </div>
        )
      )}
    </section>
  );
};

export default ProposalVariation;
