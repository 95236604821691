export const Comments = {
  index(communityId, proposalId) {
    return fetch(
      `/communities/${communityId}/proposals/${proposalId}/comments`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) => res.json());
  },

  create(communityId, proposalId, commentDescription, satisfaction) {
    return fetch(
      `/communities/${communityId}/proposals/${proposalId}/comments`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          comment: {
            description: commentDescription,
            proposal_id: proposalId,
            community_id: communityId,
            satisfaction: satisfaction,
          },
        }),
      }
    ).then((res) => res.json());
  },

  update(communityId, proposalId, commentId) {
    return fetch(
      `/communities/${communityId}/proposals/${proposalId}/comments/${commentId}`,
      {
        method: "PUT",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          comment: {
            proposal_id: proposalId,
            community_id: communityId,
            status: "reported",
            flag: true,
          },
        }),
      }
    ).then((res) => res.json());
  },
};
