import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";

const MembershipModal = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 12000);
    return () => clearTimeout(timer);
  }, []);

  const createMembership = () => {
    return fetch("/communities/add_member", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: props.community_id }),
    }).then(() => window.location.reload());
  };

  return (
    <Modal show={show} onHide={handleClose} animation={false} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          Comunidade / Community / Comunidad
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Button variant="btn btn=lg btn-warning btn-block" className="btn-warning" onClick={createMembership}>
          Entrar / Join / Entra
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default MembershipModal;
